import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import maskEmail from "../../utils/mask-email";
import styles from "./Header.scss";
import headerLogo from "../../assets/images/header-logo.png";
import classNames from "classnames";

export function Header({
 isSignedIn,
 email,
 onSignOut
}) {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.logoImageWrapper}>
          <img src={headerLogo} />
        </div>
        {/*<div className={styles.signIn}>*/}
          {/*{isSignedIn ? (*/}
          {/*  <>*/}
          {/*    <span className={styles.emailText}>*/}
          {/*      <FormattedMessage*/}
          {/*        id="header.signed-in-as"*/}
          {/*        defaultMessage="Signed in as {email}"*/}
          {/*        values={{ email: maskEmail(email) }}*/}
          {/*      />*/}
          {/*    </span>*/}
          {/*    <button className={classNames(styles.btn, styles.signOutBtn)} onClick={onSignOut}><FormattedMessage id="header.sign-out" defaultMessage="Sign Out" /></button>*/}
          {/*  </>*/}
          {/*) : (*/}
          {/*  <a className={classNames(styles.btn, styles.signInBtn)} href="/signin"><FormattedMessage id="sign-in-button" defaultMessage="Sign in/Sign up" /></a>*/}
          {/*)}*/}
        {/*</div>*/}
      </div>
    </header>
  );
}

Header.propTypes = {
  isSignedIn: PropTypes.bool,
  email: PropTypes.string,
  onSignOut: PropTypes.func
};
