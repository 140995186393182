export const joinTheWaitListLink = 'https://bluemoon.io/';

export const discordLink = 'https://discord.gg/legionnetwork';

export const twitterLink = 'https://twitter.com/Bluemoon_io';

export const instagramLink = 'https://www.instagram.com/bluemoon.io_/';

export const learnMoreLink = 'https://legion-network.gitbook.io/bluemoon-launch/';

export const deckLink = 'https://bluemoon.docsend.com/view/ptc7k3xm8qauagn4';

export const supportLink = 'http://t.me/legionnetworkcommunity';
