import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Page } from "./Page";
import { AuthContext } from "../auth/AuthContext";
import { useAccessibleOutlineStyle } from "../input/useAccessibleOutlineStyle";

export function PageContainer({ children, ...rest }) {
  const auth = useContext(AuthContext);
  useAccessibleOutlineStyle();

  return (
    <Page
      isSignedIn={auth.isSignedIn}
      email={auth.email}
      onSignOut={auth.signOut}
      {...rest}
    >
      {children}
    </Page>
  );
}

PageContainer.propTypes = {
  children: PropTypes.node
};
