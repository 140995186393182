import React from "react";
import PropTypes from "prop-types";
import "./Page.scss";
import { Header } from "./Header";
import { Footer } from "./Footer";

export function Page({
  isSignedIn,
  email,
  onSignOut,
  children,
  ...rest
}) {
  return (
    <>
      <Header
        isSignedIn={isSignedIn}
        email={email}
        onSignOut={onSignOut}
      />
      <main {...rest}>{children}</main>
      <Footer
      />
    </>
  );
}

Page.propTypes = {
  isSignedIn: PropTypes.bool,
  email: PropTypes.string,
  onSignOut: PropTypes.func,
  children: PropTypes.node
};
